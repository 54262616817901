import useContactDataMapping from '../../hooks/useContactDataMapping';
import { emptyTableRow, loadingTableRow, errorTableRow } from './Table';

function ContactDataMapping({ disabled }) {
  const { mapping: contactDataMapping, isLoading, isError } = useContactDataMapping();

  let tableBody;

  if (isLoading) tableBody = loadingTableRow;
  if (isError) tableBody = errorTableRow;
  if (contactDataMapping && contactDataMapping?.length === 0) tableBody = emptyTableRow;

  if (contactDataMapping && contactDataMapping?.length) {
    tableBody = contactDataMapping.map((mapping) => (
      <tr
        key={`${mapping.emarsysFieldId}.${mapping.sapAttributeName}`}
        data-testid={`${mapping.emarsysFieldId}.${mapping.sapAttributeName}`}
      >
        <td className="e-table__col">{mapping.emarsysFieldName}</td>
        <td className="e-table__col">{mapping.sapAttributeName}</td>
      </tr>
    ));
  }

  return (
    <>
      <e-dialoghandler
        class="e-btn e-btn-small"
        disabled={disabled ? true : null}
        dialog="#contactDataMappingDialog"
        data-testid="contactDataMappingDialogHandler"
      >
        Show Mapping
      </e-dialoghandler>
      <e-dialog id="contactDataMappingDialog" headline="Contact Data Mapping">
        <table className="e-table e-table-bordered e-table-inner_bordered e-table-condensed" data-e-version="2">
          <thead>
            <tr>
              <th className="e-table__col">Emarsys Field Name</th>
              <th className="e-table__col">SAP Commerce Cloud Attribute Name</th>
            </tr>
          </thead>
          <tbody>{tableBody}</tbody>
        </table>
      </e-dialog>
    </>
  );
}

export default ContactDataMapping;
