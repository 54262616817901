import useSWR from 'swr';
import { fetcher } from '../lib/fetcher';

export default function useProductSyncOptions() {
  const { data, error, mutate } = useSWR('/ui/product-sync-options', fetcher);

  return {
    catalogs: data?.catalogs,
    currencies: data?.currencies,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}
