import useSWR from 'swr';
import { fetcher } from '../lib/fetcher';

export default function useProgram(runId) {
  const { data } = useSWR(runId ? `ui/program/${runId}` : null, fetcher, {
    refreshInterval: 2000
  });

  return {
    data
  }
}
