import Connect from './components/Connect';
import ConnectionChangeError from './components/ConnectionChangeError';
import Main from './components/Main';
import Header from './components/Header';

import useCustomer from './hooks/useCustomer';

function App() {
  const { isDisconnected, isLoading, isError, isConnectError, isDisconnectError, customer } = useCustomer();

  let innnerSection;
  if (isLoading) {
    innnerSection = <div>Loading...</div>;
  } else if (isDisconnected) {
    innnerSection = <Connect />;
  } else if (isConnectError || isDisconnectError) {
    innnerSection = <ConnectionChangeError connectionStatus={customer.connectionStatus} />;
  } else if (isError) {
    innnerSection = (
      <e-notification type="error">
        <e-notification-title>Something went wrong...</e-notification-title>
        <e-notification-content>Please refresh the page and try again.</e-notification-content>
      </e-notification>
    );
  } else {
    innnerSection = <Main />;
  }

  return (
    <div className="e-layout e-layout-without_navigation">
      <Header />
      {innnerSection}
    </div>
  );
}

export default App;
