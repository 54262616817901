import useSWR from 'swr';
import { fetcher } from '../lib/fetcher';

export default function useCustomEvents() {
  const { data, error, mutate } = useSWR('/ui/custom-events', fetcher, { revalidateOnFocus: false });

  return {
    customEvents: data?.customEvents,
    integrationObjectCodes: data?.integrationObjectCodes,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}
