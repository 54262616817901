import useMetrics from '../../hooks/useMetrics';

function TriggersInLastHour({ propertyName }) {
  const { charts, isLoading: chartsAreLoading } = useMetrics();

  return (
    <td className="e-table__col-right" data-testid={`last-hour-${propertyName}`}>
      {chartsAreLoading && <e-spinner data-testid="loading-spinner" data-size="table"></e-spinner>}
      {!chartsAreLoading && charts[propertyName] && charts[propertyName][24].y}
      {!chartsAreLoading && !charts[propertyName] && '0'}
    </td>
  );
}

export default TriggersInLastHour;
