const loadingTableRow = (
  <tr>
    <td colSpan="100%" className="e-table__col">
      <div style={{ textAlign: 'center' }}>
        <e-spinner data-text="Loading..."></e-spinner>
      </div>
    </td>
  </tr>
);

const errorTableRow = (
  <tr>
    <td colSpan="100%" className="e-table__col">
      Something went wrong :(
    </td>
  </tr>
);

const emptyTableRow = (
  <tr>
    <td colSpan="100%" className="e-table__col">
      Mapping not yet created. Please enable sync first.
    </td>
  </tr>
);

export { loadingTableRow, errorTableRow, emptyTableRow };
