function ProductCsvSample({ disabled }) {
  return (
    <>
      <e-dialoghandler
        class="e-btn e-btn-small"
        disabled={disabled ? true : null}
        dialog="#productCsvSampleDialog"
        data-testid="productCsvSampleDialogHandler"
      >
        Product CSV sample
      </e-dialoghandler>
      <e-dialog id="productCsvSampleDialog" headline="Product CSV sample" width="50%">
        <p>
          This represents sample structure of product catalogue. You can find concrete product examples at Predict Data
          Source.
        </p>
        <pre>
          item,group_id,title,brand,description,category,price,image,zoom_image,available,link<br/>
          g/TSH-01,TSH-01,T-Shirt,Nike,Cool Nike T-Shirt in various colors and sizes,Clothing &gt; Unisex &gt; T-Shirts | Clothing &gt; Sportswear,12.34,https://my-cool-webshop.com/media/images/tshirt_thumb.jpg,https://my-cool-webshop.com/media/images/tshirt.jpg,true,https://my-cool-webshop.com/products/tsh-01<br/>
          TSH-01-BLE,TSH-01,Blue T-Shirt,Nike,Cool blue Nike T-Shirt in various sizes,Clothing &gt; Unisex &gt; T-Shirts | Clothing &gt; Sportswear,22.33,https://my-cool-webshop.com/media/images/tshirt_blue_thumb.jpg,https://my-cool-webshop.com/media/images/tshirt_blue.jpg,true,https://my-cool-webshop.com/products/tsh-01-ble<br/>
          TSH-01-BLE-S,TSH-01,Blue T-Shirt (S),Nike,Cool blue Nike T-Shirt in S size,Clothing &gt; Unisex &gt; T-Shirts | Clothing &gt; Sportswear,21.43,https://my-cool-webshop.com/media/images/tshirt_blue_s_thumb.jpg,https://my-cool-webshop.com/media/images/tshirt_blue_s.jpg,true,https://my-cool-webshop.com/products/tsh-01-ble-s<br/>
          g/OSF-BSB,OSF-BSB,One-size fits all baseball cap,Nike,Cool Nike baseball cap,Clothing &gt; Unisex &gt; Baseball caps | Clothing &gt; Sportswear,123.456,https://my-cool-webshop.com/media/images/osf_bsb_thumb.jpg,https://my-cool-webshop.com/media/images/osf_bsb.jpg,true,https://my-cool-webshop.com/products/osf-bsb-01<br/>
          /OSF-BSB,OSF-BSB,One-size fits all baseball cap,Nike,Cool Nike baseball cap,Clothing &gt; Unisex &gt; Baseball caps | Clothing &gt; Sportswear,123.456,https://my-cool-webshop.com/media/images/osf_bsb_thumb.jpg,https://my-cool-webshop.com/media/images/osf_bsb.jpg,true,https://my-cool-webshop.com/products/osf-bsb-01<br/>
        </pre>
      </e-dialog>
    </>
  );
}

export default ProductCsvSample;
