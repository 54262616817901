import useSWR from 'swr';
import { fetcher } from '../lib/fetcher';

export default function useCustomer() {
  const { data, error, mutate } = useSWR('/ui/customer', fetcher, { refreshInterval: 2000 });

  return {
    customer: data,
    isConnectError: data?.connectionStatus === 'connect_error',
    isDisconnectError: data?.connectionStatus === 'disconnect_error',
    isLoading: !error && !data,
    isError: error,
    isDisconnected: error?.status === 404,
    mutate
  };
}
