import useSWR from 'swr';
import { fetcher } from '../lib/fetcher';

export default function useContactDataMapping() {
  const { data, error, mutate } = useSWR('/ui/sample-events', fetcher);

  return {
    eventSamples: data?.eventSamples,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}
