import useMetrics from '../../hooks/useMetrics';

function ShowChart({ propertyName, eventName }) {
  const { charts, isLoading: chartsAreLoading } = useMetrics();
  if (chartsAreLoading)
    return (
      <a className="e-svgclickfix" href="#0">
        <e-icon icon="bar-chart" type="table" disabled="disabled"></e-icon>
      </a>
    );

  return (
    <>
      <e-dialoghandler dialog={`#dialog-${propertyName}`}>
        <e-tooltip valign="baseline" content="Show history">
          <a className="e-svgclickfix" href="#0">
            <e-icon icon="bar-chart" type="table"></e-icon>
          </a>
        </e-tooltip>
      </e-dialoghandler>
      <e-dialog id={`dialog-${propertyName}`} headline={eventName}>
        {charts[propertyName] && (
          <ec-chart
            data-testid="chart"
            domain-type="date"
            axis-x-date-format="time"
            format-x="date"
            tooltip-x-date-format="datetime"
          >
            <ec-series-column
              data-testid="chartColumn"
              data={JSON.stringify(charts[propertyName])}
              name="Events fired"
            ></ec-series-column>
          </ec-chart>
        )}

        {!charts[propertyName] && (
          <div style={{ height: '200px' }}>
            <div className="e-emptystate">
              <e-icon className="e-emptystate__icon" icon="bar-chart"></e-icon>
              <div className="e-emptystate__content">
                <div className="e-emptystate__title" style={{ whiteSpace: 'nowrap' }}>
                  No data to show for the last 24 hours.
                </div>
              </div>
            </div>
          </div>
        )}
      </e-dialog>
    </>
  );
}

export default ShowChart;
