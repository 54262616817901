
import { post } from '../lib/fetcher';
import useFailedJobSteps from '../hooks/useFailedJobSteps';
import useCustomer from '../hooks/useCustomer';

function ConnectionChangeError({ connectionStatus }) {
  const { failedJobSteps, isLoading} = useFailedJobSteps();
  const { isDisconnectError } = useCustomer();
  let jobStepsRendered = [];
  let errorDetails;

  const uninstallDocumentationLink = 'https://help.sap.com/docs/SAP_EMARSYS_CUSTOMER_ENGAGEMENT/b165581b13de40869ce7b59db65abb52/88ea4f727b774224a6524d544bee28c9.html'
  let errorDescription =  <div style={{ paddingBottom: '20px'}}>An error occurred in the process of connecting your SAP Emarsys Customer Engagement system with SAP Commerce Cloud. Please check the list below and solve the issue in the corresponding system. You can reset the connection and try again.</div>;
  let errorHeading = 'Connecting with SAP Commerce Cloud failed'
  let forceUninstallButtonText = 'Reset Connection';

  if(isDisconnectError){
     errorHeading = 'Disconnecting with SAP Commerce Cloud failed'
    errorDescription = <div style={{ paddingBottom: '20px'}}>An error occurred in the process of disconnecting your SAP Emarsys Customer Engagement system from 
    SAP Commerce Cloud. You can continue the disconnect anyway. Please check the list below and solve the issue in the corresponding system in order to prevent further connection issues.
    <a className="e-helperlink" href={uninstallDocumentationLink} target="_blank" rel="noopener noreferrer">
  <e-icon className="e-helperlink__icon" icon="e-helperlink" size="small"></e-icon>
  <span className="e-helperlink__text">Learn more</span>
</a>
</div>
    forceUninstallButtonText = 'Continue Disconnect';
  }

  if(!isLoading && failedJobSteps){
    jobStepsRendered = failedJobSteps.map((step) => {
      let status = '';
      if(step?.stepExecutionStatus === 'failed' && step?.errorDetails){
        status = 'error';
        errorDetails = <div><span style={{ fontWeight: 'bold'}}>Error Details:</span> {step?.errorDetails} </div>;
      }    
      if(step?.stepExecutionStatus === 'executed'){
        status = 'success';
      }     
      return <e-validator key={step?.stepName} header={step?.stepName} status={status}></e-validator>
    }
   )
  }

  async function handleConenctionReset() {
    try {
      await post('/ui/force-uninstall', {
      });
    } catch (error) {
    }
  }

  return (
    <main className="e-layout__content e-layout__content-form">
      <section className="e-layout__section e-padding-s">
      <e-illustrated-message type="error" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
        <e-icon icon="e-error" size="large" color="inherit" slot="icon"></e-icon>
          <div slot="title" style={{ width: '500px'}}>{errorHeading}</div>
          <div slot="description" style={{ width: '470px'}}>
          {errorDescription}
          <div slot="description" >
          {errorDetails}
        </div>
            <ul title="Technical Steps" className="e-list e-list-numbered" style={{paddingTop:'20px',paddingBottom:'20px'}}> 
              {jobStepsRendered}
            </ul>
          </div>

          <button className="e-btn e-btn-highlight" slot="actions" onClick={handleConenctionReset} disabled={false}>{forceUninstallButtonText}</button>
      </e-illustrated-message>
      </section>
    </main>
  );
}

export default ConnectionChangeError;