let cachedToken;

const getToken = async () => {
  if (!cachedToken) {
    cachedToken = await window.e.utils.getAuthenticationToken('sap-commerce-cloud-integration');
  }
  return cachedToken;
};

const fetcher = async (url) => {
  const token = await getToken();
  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.');
    error.status = res.status;
    throw error;
  }

  return res.json();
};

const request = async (method, url, body = {}) => {
  const token = await getToken();
  const res = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  });

  let jsonResponse = {};

  try {
    jsonResponse = await res.json();
  } catch (error) {
    jsonResponse = {};
  }

  if (jsonResponse.notification) {
    window.e.utils.openNotification(jsonResponse.notification);
  }

  if (!res.ok) {
    const error = new Error(jsonResponse.errorMessage || 'Something went wrong.');
    error.errorMessages = jsonResponse.errorMessages;
    error.status = res.status;
    throw error;
  }

  return jsonResponse;
};

const post = (url, body) => {
  return request('POST', url, body);
};

const del = (url, body = {}) => {
  return request('DELETE', url, body);
};

export { fetcher, post, del };
