import { useState } from 'react';
import { generate } from 'generate-password';
import { post } from '../lib/fetcher';

function Connect() {
  const [backofficeUrl, setBackofficeUrl] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [testAndConnectEnabled, setTestAndConnectEnabled] = useState(true);

  const [username] = useState(generate({ length: 24, numbers: true, strict: true }));
  const [password] = useState(generate({ length: 24, numbers: true, symbols: true, strict: true }));

  function handleBackofficeUrlChange(e) {
    setBackofficeUrl(e.target.value);
    setErrorMessages([]);
  }

  async function handleTestAndConnectClick() {
    setTestAndConnectEnabled(false);

    try {
      await post('/ui/test-and-connect', {
        username,
        password,
        backofficeUrl
      });
    } catch (error) {
      setTestAndConnectEnabled(true);
      setErrorMessages(error.errorMessages);
    }
  }

  const notification = () => {
    if (errorMessages.length) {
      return errorMessages.map((errorMessage) => (
        <e-notification closable type="error" key={errorMessage}>
          <e-notification-content>{errorMessage}</e-notification-content>
        </e-notification>
      ));
    }
  };

  return (
    <main className="e-layout__content e-layout__content-form">
      <section className="e-layout__section e-padding-s">
        <h2>Connect SAP Commerce Cloud environment</h2>

        <e-notification type="info">
          <e-notification-content>
            Create an administrator user in SAP Commerce Cloud with the following credentials.
          </e-notification-content>
        </e-notification>

        <div className="e-field e-field-fixwidth e-margin-top-m">
          <label className="e-field__label e-field__label-inline">
            Username: <strong>{username}</strong>
          </label>
        </div>
        <div className="e-field e-field-fixwidth">
          <label className="e-field__label e-field__label-inline">
            Password: <strong>{password}</strong>
          </label>
        </div>
        <div className="e-field">
          <label htmlFor="backofficeUrl" className="e-field__label">
            URL
          </label>
          <input
            className="e-input"
            id="backofficeUrl"
            type="text"
            placeholder="URL"
            value={backofficeUrl}
            onChange={handleBackofficeUrlChange}
          />
        </div>
        {notification()}
        <button onClick={handleTestAndConnectClick} className="e-btn e-btn-primary" disabled={!testAndConnectEnabled}>
          Test & Connect
        </button>
      </section>
    </main>
  );
}

export default Connect;
