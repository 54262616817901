import { useState, useEffect } from 'react';

import { post } from '../../lib/fetcher';
import useCustomer from '../../hooks/useCustomer';

import ConsentSelector from './ConsentSelector';
import ContactDataMapping from './ContactDataMapping';
import useProgram from '../../hooks/useProgram';

function ContactSyncPanelContent() {
  const { customer, mutate } = useCustomer();

  const [localCustomer, setLocalCustomer] = useState({
    contactSync: 'disabled',
    contactSyncRunId: null,
    consentSyncEnabled: false,
    syncRegisteredCustomersOnly: false,
    matchContactsAlsoByEmail: false,
    filterCustomersByPrefix: false,
    customerFilterPrefix: ''
  });
  const [programError, setProgramError] = useState('');

  const { data } = useProgram(customer.contactSyncRunId);

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (!isDirty) {
      setLocalCustomer(customer);
    }
    if (data) checkB2BContactSyncErrorState(data);
  }, [customer, data, isDirty]);

  async function checkB2BContactSyncErrorState(data) {
    const response = await post('ui/validate-program', { runId: data.runId });
    setProgramError(response.errorMessage);
  }

  async function handleSaveClick() {
    await post('/ui/sync/contacts', {
      enabled: localCustomer.contactSync === 'enabled',
      consentSyncEnabled: localCustomer.consentSyncEnabled,
      baseSite: localCustomer.baseSite,
      consentTemplateId: localCustomer.consentTemplateId,
      consentTemplateVersion: localCustomer.consentTemplateVersion,
      syncRegisteredCustomersOnly: localCustomer.syncRegisteredCustomersOnly,
      matchContactsAlsoByEmail: localCustomer.matchContactsAlsoByEmail,
      filterCustomersByPrefix: localCustomer.filterCustomersByPrefix,
      customerFilterPrefix: localCustomer.customerFilterPrefix
    });
    mutate(localCustomer);
    setIsDirty(false);
  }

  async function handleResyncClick() {
    await post('/ui/resync/b2b-contacts', {});
    mutate();
  }

  const onEnableSwitchChange = () => {
    const originalState = customer.contactSync;
    const switchingTo = localCustomer.contactSync === 'disabled' ? 'enabled' : 'disabled';
    const consentSync = switchingTo === 'disabled' ? { consentSyncEnabled: false } : {};
    const syncRegisteredCustomers = switchingTo === 'disabled' ? { syncRegisteredCustomersOnly: false } : {};
    const matchContactsAlsoByEmailSetting =
      switchingTo === 'disabled'
        ? { matchContactsAlsoByEmail: false }
        : customer.businessToBusinessCustomerExists
        ? { matchContactsAlsoByEmail: true }
        : {};
    const filterCustomersByPrefixSetting =
      switchingTo === 'disabled' ? { filterCustomersByPrefix: false, customerFilterPrefix: '' } : {};

    if (originalState === switchingTo) {
      setLocalCustomer(customer);
      setIsDirty(false);
    } else {
      setLocalCustomer({
        ...localCustomer,
        contactSync: switchingTo,
        ...consentSync,
        ...syncRegisteredCustomers,
        ...matchContactsAlsoByEmailSetting,
        ...filterCustomersByPrefixSetting
      });
      setIsDirty(true);
    }
  };

  const getEnableSwitchState = () => {
    const { contactSync } = localCustomer;
    return {
      checked: contactSync === 'enabling_in_progress' || contactSync === 'enabled',
      disabled: contactSync.includes('in_progress')
    };
  };

  const onConsentSyncEnableCheckboxChange = () => {
    setLocalCustomer({
      ...localCustomer,
      consentSyncEnabled: !localCustomer.consentSyncEnabled
    });
    setIsDirty(true);
  };

  const getConsentSyncEnableCheckboxState = () => {
    const originalState = customer.contactSync;

    const { consentSyncEnabled, contactSync } = localCustomer;
    return {
      checked: consentSyncEnabled,
      disabled: !(contactSync === 'enabled' || contactSync === 'enabling_in_progress') || originalState === contactSync
    };
  };

  const onFilterCustomersWithPrefixCheckboxChange = () => {
    setLocalCustomer({
      ...localCustomer,
      filterCustomersByPrefix: !localCustomer.filterCustomersByPrefix
    });
    setIsDirty(true);
  };

  const getFilterCustomersWithPrefixCheckboxState = () => {
    const originalState = customer.contactSync;

    const { filterCustomersByPrefix, contactSync } = localCustomer;
    return {
      checked: filterCustomersByPrefix,
      disabled: !(contactSync === 'enabled' || contactSync === 'enabling_in_progress') || originalState === contactSync
    };
  };

  function handleCustomerFilterPrefixChange(e) {
    setIsDirty(true);
    setLocalCustomer({
      ...localCustomer,
      customerFilterPrefix: e.target.value
    });
  }

  const getCustomerFilterPrefixInputState = () => {
    const originalState = customer.contactSync;
    const { filterCustomersByPrefix, contactSync } = localCustomer;

    const isCheckboxDisabled =
      !(contactSync === 'enabled' || contactSync === 'enabling_in_progress') || originalState === contactSync;
    const isCheckboxChecked = filterCustomersByPrefix;

    return {
      disabled: isCheckboxDisabled || !isCheckboxChecked
    };
  };

  const onMatchContactsAlsoByEmailCheckboxChange = () => {
    setLocalCustomer({
      ...localCustomer,
      matchContactsAlsoByEmail: !localCustomer.matchContactsAlsoByEmail
    });
    setIsDirty(true);
  };

  const getMatchContactsAlsoByEmailCheckboxState = () => {
    const originalState = customer.contactSync;
    const { matchContactsAlsoByEmail, contactSync } = localCustomer;

    return {
      checked: matchContactsAlsoByEmail,
      disabled:
        !(contactSync === 'enabled' || contactSync === 'enabling_in_progress') ||
        originalState === contactSync ||
        customer.businessToBusinessCustomerExists
    };
  };

  const onSyncRegisteredCustomersOnlyCheckboxChange = () => {
    setLocalCustomer({
      ...localCustomer,
      syncRegisteredCustomersOnly: !localCustomer.syncRegisteredCustomersOnly
    });
    setIsDirty(true);
  };

  const getSyncRegisteredCustomersOnlyCheckboxState = () => {
    const originalState = customer.contactSync;

    const { syncRegisteredCustomersOnly, contactSync } = localCustomer;
    return {
      checked: syncRegisteredCustomersOnly,
      disabled: !(contactSync === 'enabled' || contactSync === 'enabling_in_progress') || originalState === contactSync
    };
  };

  const onConsentSelectorChange = (change) => {
    setLocalCustomer({
      ...localCustomer,
      baseSite: change.baseSite,
      consentTemplateId: change.consentTemplateId,
      consentTemplateVersion: change.consentTemplateVersion
    });
  };

  const isConsentSelectorDisabled = () => {
    const originalState = customer.contactSync;
    const { contactSync } = localCustomer;

    return originalState === contactSync;
  };

  const isSaveButtonDisabled = () => {
    if (localCustomer.contactSync === 'enabled' && localCustomer.consentSyncEnabled && isDirty) {
      const areConsentSettingsValid =
        localCustomer.baseSite && localCustomer.consentTemplateId && localCustomer.consentTemplateVersion;
      return !areConsentSettingsValid;
    }
    return !isDirty;
  };

  return (
    <>
      <div className="e-margin-bottom-m">
        {!!programError && (
          <e-notification type="danger" data-testid="errorNotification">
            <e-notification-content>
              <ul className="e-margin-none">
                <li>{programError}</li>
              </ul>
            </e-notification-content>
          </e-notification>
        )}
        <e-notification type="info">
          <e-notification-content>
            <ul className="e-margin-none">
              <li>
                Enabling customer sync will create the necessary Integration Objects, Outbount Sync processes, and
                Webhooks.
              </li>
              <li>
                The progress of the initial synchronization can be monitored inside the Backoffice of your SAP Commerce
                Cloud environment.
              </li>
            </ul>
          </e-notification-content>
        </e-notification>
      </div>
      <div className="e-field">
        <div className="e-switch">
          <input
            className="e-switch__input"
            id="switch-withlabel"
            type="checkbox"
            {...getEnableSwitchState()}
            onChange={onEnableSwitchChange}
          />
          <label className="e-switch__toggle" htmlFor="switch-withlabel"></label>
        </div>
        <label className="e-field__label-inline" htmlFor="switch-withlabel">
          Enable Contact Sync
        </label>

        <div className="e-margin-left-2xl">
          <ContactDataMapping disabled={localCustomer.contactSync !== 'enabled'} />

          {customer.businessToBusinessCustomerExists && (
            <button
              disabled={customer.contactSync !== 'enabled' || isDirty}
              className="e-margin-left-xs e-btn e-btn-small"
              onClick={handleResyncClick}
              data-testid="resyncB2BCustomersButton"
            >
              Resync B2B Customers
            </button>
          )}
        </div>

        <div className="e-margin-left-2xl e-margin-top-m">
          <div className="e-field">
            <input
              id="matchContactsAlsoByEmail"
              className="e-checkbox"
              type="checkbox"
              {...getMatchContactsAlsoByEmailCheckboxState()}
              onChange={onMatchContactsAlsoByEmailCheckboxChange}
            />
            <label htmlFor="matchContactsAlsoByEmail">Try matching contacts also by email.</label>
          </div>
        </div>

        <div className="e-margin-left-2xl e-margin-top-m">
          <div className="e-field">
            <input
              id="syncRegisteredCustomersOnly"
              className="e-checkbox"
              type="checkbox"
              {...getSyncRegisteredCustomersOnlyCheckboxState()}
              onChange={onSyncRegisteredCustomersOnlyCheckboxChange}
            />
            <label htmlFor="syncRegisteredCustomersOnly">Sync registered customers only.</label>
          </div>
        </div>

        <div className="e-margin-left-2xl e-margin-top-m">
          <div className="e-field">
            <input
              id="filterCustomersWithPrefix"
              className="e-checkbox"
              type="checkbox"
              {...getFilterCustomersWithPrefixCheckboxState()}
              onChange={onFilterCustomersWithPrefixCheckboxChange}
            />
            <label htmlFor="filterCustomersWithPrefix">Reject customers with prefix.</label>
            <e-tooltip
              content="Customers whose customer ID starts with the prefix will not be synchronised to Emarsys."
              type="helper"
              has-action
            ></e-tooltip>
          </div>
        </div>

        <div className="e-field e-field-fixwidth e-padding-left-4xl">
          <input
            data-testid="customerFilterPrefixInput"
            className="e-input"
            id="customerFilterPrefix"
            type="text"
            aria-label="Customer Filter Prefix"
            {...getCustomerFilterPrefixInputState()}
            value={localCustomer.customerFilterPrefix || ''}
            onChange={handleCustomerFilterPrefixChange}
            maxLength="1024"
          />
        </div>

        <div className="e-margin-left-2xl e-margin-top-m">
          <div className="e-field">
            <input
              id="consentSyncEnabled"
              className="e-checkbox"
              type="checkbox"
              {...getConsentSyncEnableCheckboxState()}
              onChange={onConsentSyncEnableCheckboxChange}
            />
            <label htmlFor="consentSyncEnabled">Sync consents.</label>
          </div>

          {localCustomer.consentSyncEnabled && (
            <ConsentSelector
              customer={customer}
              isDirty={isDirty}
              setIsDirty={setIsDirty}
              change={onConsentSelectorChange}
              disabled={isConsentSelectorDisabled()}
            />
          )}
        </div>
      </div>
      <button className="e-btn e-btn-primary" onClick={handleSaveClick} disabled={isSaveButtonDisabled()}>
        {customer.contactSync.includes('in_progress') && (
          <div className="e-btn__loading e-btn__loading-active">
            <e-spinner data-size="small"></e-spinner>
          </div>
        )}
        Save
      </button>
    </>
  );
}

export default ContactSyncPanelContent;
