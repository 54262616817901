import useSWR from 'swr';
import { fetcher } from '../lib/fetcher';

export default function useConsentTemplate() {
  const { data, error, mutate } = useSWR('/ui/consent-templates', fetcher);

  return {
    consentTemplates: data?.consentTemplates,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}
