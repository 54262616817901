import { uniqBy } from 'lodash';
import { useRef, useEffect, useState } from 'react';
import useConsentTemplate from '../../hooks/useConsentTemplate';

function ConsentSelector({ customer, isDirty, setIsDirty, change, disabled }) {
  const { consentTemplates, isLoading, isError } = useConsentTemplate();

  const baseSiteSelect = useRef(null);
  const consentTemplateSelect = useRef(null);
  const consentTemplateVersionSelect = useRef(null);

  const [baseSite, setBaseSite] = useState(undefined);
  const [consentTemplateId, setConsentTemplateId] = useState(undefined);
  const [consentTemplateVersion, setConsentTemplateVersion] = useState(undefined);

  useEffect(() => {
    const baseSiteChangeListener = (e) => {
      setIsDirty(true);
      setBaseSite(e.detail.value);
      setConsentTemplateId(undefined);
      setConsentTemplateVersion(undefined);
      change({ baseSite: e.detail.value, consentTemplateId: undefined, consentTemplateVersion: undefined });
    };

    if (baseSiteSelect && baseSiteSelect.current) {
      const current = baseSiteSelect.current;
      current.addEventListener('change', baseSiteChangeListener);

      return () => {
        current.removeEventListener('change', baseSiteChangeListener);
      };
    }
  });

  useEffect(() => {
    const consentTemplateIdChangeListener = (e) => {
      setIsDirty(true);
      setConsentTemplateId(e.detail.value);
      setConsentTemplateVersion(undefined);
      change({ baseSite, consentTemplateId: e.detail.value, consentTemplateVersion: undefined });
    };

    if (consentTemplateSelect && consentTemplateSelect.current) {
      const current = consentTemplateSelect.current;
      current.addEventListener('change', consentTemplateIdChangeListener);

      return () => {
        current.removeEventListener('change', consentTemplateIdChangeListener);
      };
    }
  });

  useEffect(() => {
    const consentTemplateVersionChangeListener = (e) => {
      setIsDirty(true);
      setConsentTemplateVersion(e.detail.value);
      change({ baseSite, consentTemplateId, consentTemplateVersion: e.detail.value });
    };

    if (consentTemplateVersionSelect && consentTemplateVersionSelect.current) {
      const current = consentTemplateVersionSelect.current;
      current.addEventListener('change', consentTemplateVersionChangeListener);

      return () => {
        current.removeEventListener('change', consentTemplateVersionChangeListener);
      };
    }
  });

  useEffect(() => {
    if (isDirty) return;

    setBaseSite(customer.baseSite);
    setConsentTemplateId(customer.consentTemplateId);
    setConsentTemplateVersion(customer.consentTemplateVersion);
  }, [customer, isDirty]);

  if (isLoading) return <e-spinner data-size="small" data-text="Loading consent templates..."></e-spinner>;
  if (isError)
    return (
      <e-notification type="error">
        <e-notification-content>Unable to load consent templates.</e-notification-content>
      </e-notification>
    );

  const baseSitesOptions = uniqBy(consentTemplates, 'baseSiteIntegrationKey').map(({ baseSiteIntegrationKey }) => (
    <e-select-option
      value={baseSiteIntegrationKey}
      key={baseSiteIntegrationKey}
      selected={baseSite === baseSiteIntegrationKey}
    >
      {baseSiteIntegrationKey}
    </e-select-option>
  ));

  const consentTemplateOptions = [
    ...new Set(
      consentTemplates
        .filter((consentTemplate) => consentTemplate.baseSiteIntegrationKey === baseSite)
        .map((consentTemplate) => consentTemplate.id)
    )
  ].map((templateId) => (
    <e-select-option value={templateId} key={templateId} selected={consentTemplateId === templateId}>
      {templateId}
    </e-select-option>
  ));

  const consentTemplateVersions = consentTemplates
    .filter((consentTemplate) => consentTemplate.baseSiteIntegrationKey === baseSite)
    .filter((consentTemplate) => consentTemplate.id === consentTemplateId)
    .map(({ version }) => (
      <e-select-option value={version} key={version} selected={consentTemplateVersion === version}>
        {version}
      </e-select-option>
    ));

  return (
    <>
      <div className="e-field e-field-fixwidth e-padding-left-l">
        <label className="e-field__label" htmlFor="base-site-dropdown">
          Base Site
        </label>
        <e-select
          id="base-site-dropdown"
          placeholder="Please select one option"
          ref={baseSiteSelect}
          aria-label="Base site"
          disabled={disabled}
        >
          {baseSitesOptions}
        </e-select>
      </div>

      <div className="e-field e-field-fixwidth e-padding-left-l">
        <label className="e-field__label" htmlFor="consent-template-dropdown">
          Consent Template
        </label>
        <e-select
          id="consent-template-dropdown"
          placeholder="Please select one option"
          ref={consentTemplateSelect}
          aria-label="Consent Template"
          disabled={disabled}
        >
          {consentTemplateOptions}
        </e-select>
      </div>

      <div className="e-field e-field-fixwidth e-padding-left-l">
        <label className="e-field__label" htmlFor="consent-template-version-dropdown">
          Consent Template Version
        </label>
        <e-select
          id="consent-template-version-dropdown"
          placeholder="Please select one option"
          ref={consentTemplateVersionSelect}
          aria-label="Consent Template Version"
          disabled={disabled}
        >
          {consentTemplateVersions}
        </e-select>
      </div>
    </>
  );
}

export default ConsentSelector;
