import useSWR from 'swr';
import { fetcher } from '../lib/fetcher';

export default function useCustomer() {
  const { data, error, mutate } = useSWR('/ui/failed-jobs', fetcher, { refreshInterval: 2000 });

  return {
    failedJobSteps: data,
    isLoading: !error && !data,
    error: error,
    mutate
  };
}
