import { useState } from 'react';

import ContactSyncPanelContent from './contact-sync-tab/PanelContent';
import RealTimeEventsPanelContent from './real-time-events-tab/PanelContent';
import ProductSyncPanelContent from './product-sync-tab/PanelContent';
import useCustomer from '../hooks/useCustomer';

function Main() {
  const { customer } = useCustomer();
  const [currentTab, setCurrentTab] = useState('contacts');

  const onTabChange = (event) => {
    setCurrentTab(event.target.value);
  };

  return (
    <main className="e-layout__content">
      {customer.connectionStatus !== 'connected' && (
        <div className="overlaycontainer">
          <div className="e-overlay e-overlay-centered">
            <div className="e-overlay__center">
              <e-spinner data-testid="spinner"></e-spinner>
            </div>
          </div>
        </div>
      )}
      <section className="e-layout__section">
        <h2 className="e-margin-m">Sync SAP data to Emarsys</h2>
        <div className="e-tabs e-margin-m">
          <input
            type="radio"
            name="main-tabs"
            id="tabs-contacts"
            defaultChecked="checked"
            value="contacts"
            onChange={onTabChange}
          />
          <label className="e-tabs__title" htmlFor="tabs-contacts">
            <span className="e-tabs__separator">Contacts</span>
          </label>
          <input
            type="radio"
            name="main-tabs"
            id="tabs-real-time-events"
            value="real-time-events"
            onChange={onTabChange}
          />
          <label className="e-tabs__title" htmlFor="tabs-real-time-events">
            <span className="e-tabs__separator">Real-time events</span>
          </label>
          <input type="radio" name="main-tabs" id="tabs-products" value="products" onChange={onTabChange} />
          <label className="e-tabs__title" htmlFor="tabs-products">
            <span className="e-tabs__separator">Products</span>
          </label>
          <div className="e-tabs__panel">
            <div className="e-tabs__panel__content">{currentTab === 'contacts' && <ContactSyncPanelContent />}</div>
            <div className="e-tabs__panel__content">
              {currentTab === 'real-time-events' && <RealTimeEventsPanelContent />}
            </div>
            <div className="e-tabs__panel__content">{currentTab === 'products' && <ProductSyncPanelContent />}</div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Main;
