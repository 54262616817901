import { useRef, useEffect, useState } from 'react';
import { post } from '../../lib/fetcher';

function NewCustomEvent({ integrationObjectCodes, onSave }) {
  const newCustomEventDialog = useRef(null);
  const saveButton = useRef(null);
  const nameInput = useRef(null);
  const integrationObjectCodeSelect = useRef(null);

  const [isSaving, setIsSaving] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [customEventName, setCustomEventName] = useState('');
  const [customEventIntegrationObjectCode, setCustomEventIntegrationObjectCode] = useState('');

  useEffect(() => {
    setIsValid(customEventName.trim() !== '' && customEventIntegrationObjectCode !== '');
  }, [customEventName, customEventIntegrationObjectCode]);

  useEffect(() => {
    const current = saveButton.current;
    const saveClickListener = async () => {
      setIsSaving(true);

      let response;
      try {
        response = await post('/ui/custom-events', {
          name: customEventName,
          integrationObjectCode: customEventIntegrationObjectCode
        });
      } catch (error) {
        setIsSaving(false);
        return;
      }

      newCustomEventDialog.current.close();

      setCustomEventName('');
      setCustomEventIntegrationObjectCode('');
      nameInput.current.value = '';
      setIsSaving(false);

      onSave(response);
    };

    current.addEventListener('click', saveClickListener);
    return () => current.removeEventListener('click', saveClickListener);
  }, [saveButton, customEventName, customEventIntegrationObjectCode, onSave]);

  useEffect(() => {
    if (integrationObjectCodeSelect.current) {
      integrationObjectCodeSelect.current.addEventListener('change', function (e) {
        setCustomEventIntegrationObjectCode(e.detail.value);
      });
    }
  }, [integrationObjectCodes, integrationObjectCodeSelect]);

  useEffect(() => {
    nameInput.current.addEventListener('input', function () {
      setCustomEventName(nameInput.current.value);
    });
  }, [nameInput]);

  return (
    <e-dialog ref={newCustomEventDialog} id="new-custom-event" headline="New Custom Event" no-close={isSaving}>
      <div className="e-field">
        <label className="e-field__label" htmlFor="custom-event-name">
          Name
        </label>
        <input
          ref={nameInput}
          className="e-input"
          id="custom-event-name"
          type="text"
          placeholder="Custom Event Name"
          disabled={isSaving}
        />
      </div>

      <div className="e-field">
        <label className="e-field__label" htmlFor="custom-event-integration-object-code">
          Integration Object
        </label>

        {integrationObjectCodes && (
          <e-select
            ref={integrationObjectCodeSelect}
            id="custom-event-integration-object-code"
            placeholder="Select One Option"
            data-testid="custom-event-integration-object-code-select"
            disabled={isSaving}
          >
            {integrationObjectCodes.map((integrationObjectCode) => (
              <e-select-option key={integrationObjectCode} value={integrationObjectCode}>
                {integrationObjectCode}
              </e-select-option>
            ))}
          </e-select>
        )}
      </div>

      <div className="e-dialog__footer">
        <div className="e-grid e-grid-small">
          <div className="e-cell e-cell-small">
            <button className="e-btn" data-action="close" disabled={isSaving}>
              Close
            </button>
          </div>
          <div className="e-cell e-cell-small">
            <button ref={saveButton} className="e-btn e-btn-primary" disabled={isSaving || !isValid}>
              {isSaving && <e-spinner data-size="small"></e-spinner>}
              Save
            </button>
          </div>
        </div>
      </div>
    </e-dialog>
  );
}

export default NewCustomEvent;
