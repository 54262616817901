import useSWR from 'swr';
import { fetcher } from '../lib/fetcher';

export default function useContactDataMapping() {
  const { data, error, mutate } = useSWR('/ui/mapping/contact', fetcher);

  return {
    mapping: data?.mapping,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}
