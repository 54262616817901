import useSWR from 'swr';
import { fetcher } from '../lib/fetcher';

export default function useMetrics() {
  const { data: charts, error } = useSWR('/ui/metrics', fetcher, { refreshInterval: 60000 });

  const formattedCharts = {};
  for (const eventType in charts) {
    formattedCharts[eventType] = [];
    charts[eventType].forEach((chartData) => (formattedCharts[eventType].push({ x: chartData.date, y: chartData.count })));
  }

  return {
    charts: formattedCharts,
    isLoading: !error && !charts,
    isError: error
  };
}
