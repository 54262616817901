import useCustomer from '../hooks/useCustomer';
import { post } from '../lib/fetcher';

function Header() {
  const { customer, isDisconnected, mutate, isConnectError,  isDisconnectError } = useCustomer();

  async function disableIntegrationClick() {
    await post('/ui/uninstall', {});
  }

  function dialogHandler () {
    window.e.utils.openDestructiveConfirmationDialog({
      headline: 'Disable Integration',
      content: `<p>
                  Are you sure you want to terminate the connection between Emarsys and your SAP Commerce Cloud
                  environment?
                </p>
                <p>
                  <strong>Important:</strong> Please note that terminating the connection will stop all ongoing synchronization processes
                  and it might impact your Automation programs. After terminating the connection, you can reestablish the
                  connection again which involves resynchronising all data.
                </p>`,
      confirm: {
        label: 'Disable integration',
        callback() {
          mutate({...customer, connectionStatus: 'uninstalling'});
          disableIntegrationClick();
        }
      },
      cancel: {
        label: 'Cancel'
      }
    });
  };

  return (
    <header className="e-layout__header">
      <h1 className="e-layout__title">SAP Commerce Cloud</h1>
      <div className="e-layout__actions">
        {isDisconnected || isConnectError || isDisconnectError || (
          <button type="button" className="e-layout__action e-btn" onClick={dialogHandler}>
            Disable integration
          </button>
        )}
      </div>
    </header>
  );
}

export default Header;
