import { useState, useEffect, useRef } from 'react';

import { post } from '../../lib/fetcher';
import useCustomer from '../../hooks/useCustomer';
import useProductSyncOptions from '../../hooks/useProductSyncOptions';
import ProductCsvSample from './ProductCsvSample';

function ProductSyncTabContent() {
  const { customer, mutate } = useCustomer();
  const { catalogs, currencies, isLoading, isError } = useProductSyncOptions();

  const productCatalogMultiselect = useRef(null);
  const currencySelect = useRef(null);

  const [localCustomer, setLocalCustomer] = useState({
    productSync: 'disabled',
    productCatalogs: [],
    productCatalogCurrency: undefined,
    imageUrlPrefix: '',
    productUrlTemplate: ''
  });

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (!isDirty) setLocalCustomer(customer);
  }, [customer, isDirty]);

  useEffect(() => {
    const currencyChangeListener = (e) => {
      setIsDirty(true);
      setLocalCustomer({
        ...localCustomer,
        productCatalogCurrency: e.detail.value
      });
    };

    if (currencySelect && currencySelect.current) {
      const current = currencySelect.current;
      current.addEventListener('change', currencyChangeListener);

      return () => {
        current.removeEventListener('change', currencyChangeListener);
      };
    }
  });

  useEffect(() => {
    const productCatalogsChangeListener = (e) => {
      setIsDirty(true);
      setLocalCustomer({
        ...localCustomer,
        productCatalogs: e.detail.selectedOptions.map((option) => JSON.parse(option.value))
      });
    };

    if (productCatalogMultiselect && productCatalogMultiselect.current) {
      const current = productCatalogMultiselect.current;
      current.addEventListener('change', productCatalogsChangeListener);

      return () => {
        current.removeEventListener('change', productCatalogsChangeListener);
      };
    }
  });

  function handleImageUrlPrefixChange(e) {
    setIsDirty(true);
    setLocalCustomer({
      ...localCustomer,
      imageUrlPrefix: e.target.value
    });
  }

  function handleProductUrlTemplateChange(e) {
    setIsDirty(true);
    setLocalCustomer({
      ...localCustomer,
      productUrlTemplate: e.target.value
    });
  }

  async function handleSaveClick() {
    const enabled = localCustomer.productSync === 'enabled';
    await post('/ui/sync/products', {
      enabled,
      productCatalogs: enabled ? localCustomer.productCatalogs : [],
      productCatalogCurrency: enabled ? localCustomer.productCatalogCurrency : undefined,
      imageUrlPrefix: enabled ? localCustomer.imageUrlPrefix : '',
      productUrlTemplate: enabled ? localCustomer.productUrlTemplate : ''
    });
    mutate(localCustomer);
    setIsDirty(false);
  }

  if (isLoading) return <e-spinner data-size="small" data-text="Loading catalogs..."></e-spinner>;
  if (isError)
    return (
      <e-notification type="error">
        <e-notification-content>Unable to load catalogs.</e-notification-content>
      </e-notification>
    );

  const onEnableSwitchChange = () => {
    const originalState = customer.productSync;
    const switchingTo = localCustomer.productSync === 'disabled' ? 'enabled' : 'disabled';

    if (originalState === switchingTo) {
      setLocalCustomer(customer);
      setIsDirty(false);
    } else {
      setLocalCustomer({
        ...localCustomer,
        productSync: switchingTo
      });
      setIsDirty(true);
    }
  };

  const getEnableSwitchState = () => {
    const { productSync } = localCustomer;
    return {
      checked: productSync === 'enabling_in_progress' || productSync === 'enabled',
      disabled: productSync.includes('in_progress')
    };
  };

  const getProductSyncConfigState = () => {
    const originalState = customer.productSync;
    const { productSync } = localCustomer;

    return {
      disabled: !(productSync === 'enabled' || productSync === 'enabling_in_progress') || originalState === productSync
    };
  };

  const productCatalogOptions = catalogs.map((catalog) => {
    const isSelected = localCustomer.productCatalogs.find(
      (selectedCatalog) => selectedCatalog.id === catalog.id && selectedCatalog.version === catalog.version
    );
    return (
      <e-select-option value={JSON.stringify(catalog)} key={catalog.integrationKey} selected={isSelected}>
        {catalog.name} (version: {catalog.version})
      </e-select-option>
    );
  });

  const currencyOptions = currencies.map(({ isocode }) => (
    <e-select-option value={isocode} key={isocode} selected={localCustomer.productCatalogCurrency === isocode}>
      {isocode}
    </e-select-option>
  ));

  const isSaveButtonDisabled = () => {
    if (localCustomer.productSync === 'enabled' && isDirty) {
      return (
        localCustomer.productCatalogs.length === 0 ||
        !localCustomer.productCatalogCurrency ||
        !localCustomer.imageUrlPrefix ||
        !localCustomer.productUrlTemplate
      );
    }
    return !isDirty;
  };

  return (
    <>
      <div className="e-field">
        <div className="e-switch">
          <input
            className="e-switch__input"
            id="switch-withlabel"
            type="checkbox"
            {...getEnableSwitchState()}
            onChange={onEnableSwitchChange}
          />
          <label className="e-switch__toggle" htmlFor="switch-withlabel"></label>
        </div>
        <label className="e-field__label-inline" htmlFor="switch-withlabel">
          Enable product sync
        </label>

        <div className="e-margin-left-2xl">
          <ProductCsvSample
            disabled={localCustomer.productSync !== 'enabled'}
          />
        </div>
      </div>

      <div className="e-field e-padding-left-2xl">
        <label className="e-field__label" htmlFor="product-catalog-multiselect">
          Product catalogs
        </label>
        <e-multiselect
          id="product-catalog-multiselect"
          ref={productCatalogMultiselect}
          aria-label="Product catalogs"
          {...getProductSyncConfigState()}
        >
          {productCatalogOptions}
        </e-multiselect>
      </div>

      <div className="e-field e-padding-left-2xl">
        <label className="e-field__label" htmlFor="imageUrlPrefix">
          Product images domain
        </label>
        <input
          className="e-input"
          id="imageUrlPrefix"
          type="text"
          aria-label="Product images domain"
          {...getProductSyncConfigState()}
          value={localCustomer.imageUrlPrefix || ''}
          onChange={handleImageUrlPrefixChange}
          maxLength="1024"
        />
      </div>

      <div className="e-field e-padding-left-2xl">
        <label className="e-field__label" htmlFor="imageUrlPrefix">
          Product URL template
        </label>
        <input
          className="e-input"
          id="productUrlTemplate"
          type="text"
          aria-label="Product URL template"
          {...getProductSyncConfigState()}
          value={localCustomer.productUrlTemplate || ''}
          onChange={handleProductUrlTemplateChange}
          maxLength="1024"
        />
      </div>

      <div className="e-field e-field-fixwidth e-padding-left-2xl">
        <label className="e-field__label" htmlFor="currency-dropdown">
          Currency
        </label>
        <e-select
          id="currency-dropdown"
          placeholder="Please select one option"
          ref={currencySelect}
          aria-label="Currency"
          {...getProductSyncConfigState()}
        >
          {currencyOptions}
        </e-select>
      </div>

      <button className="e-btn e-btn-primary" onClick={handleSaveClick} disabled={isSaveButtonDisabled()}>
        {customer.productSync.includes('in_progress') && (
          <div className="e-btn__loading e-btn__loading-active">
            <e-spinner data-size="small"></e-spinner>
          </div>
        )}
        Save
      </button>
    </>
  );
}

export default ProductSyncTabContent;
