import { useState, useEffect, useRef } from 'react';

import { post } from '../../lib/fetcher';
import useCustomer from '../../hooks/useCustomer';
import useEventSamples from '../../hooks/useEventSamples';

import TriggersInLastHour from './TriggersInLastHour';
import ShowChart from './ShowChart';
import CustomEvents from './CustomEvents';

export const defaultCharts = [
  {
    propertyName: 'orderUpdate',
    title: 'All Order Update',
    sampleEventType: 'order-update',
    tooltip: 'All order update events. Including those which stays in the same state.'
  },
  {
    propertyName: 'orderUpdateCreated',
    title: 'Order Created',
    sampleEventType: 'order-update',
    tooltip: 'All order update events which are in the created state.'
  },
  {
    propertyName: 'orderUpdateCancelled',
    title: 'Order Cancelled',
    sampleEventType: 'order-update',
    tooltip: 'All order update events which are in the cancelled state.'
  },
  {
    propertyName: 'orderUpdateShipped',
    title: 'Order Shipped',
    sampleEventType: 'order-update',
    tooltip: 'All order update events which are in the shipped state.'
  },
  {
    propertyName: 'orderUpdateCompleted',
    title: 'Order Completed',
    sampleEventType: 'order-update',
    tooltip: 'All order update events which are in the completed state.'
  },
  { propertyName: 'cart', title: 'Cart', sampleEventType: 'cart' },
  { propertyName: 'review', title: 'Review', sampleEventType: 'review' },
  { propertyName: 'registration', title: 'Registration', sampleEventType: 'registration' },
  { propertyName: 'optIn', title: 'Opt-in', sampleEventType: 'opt-in' },
  { propertyName: 'optOut', title: 'Opt-out', sampleEventType: 'opt-out' }
];

function RealTimeEventsPanelContent() {
  const { customer, mutate } = useCustomer();
  const { eventSamples, isLoading: eventSamplesAreLoading, isError: eventSamplesError } = useEventSamples();

  const [localCustomer, setLocalCustomer] = useState({ eventTriggering: 'disabled' });
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (!isDirty) setLocalCustomer(customer);
  }, [customer, isDirty]);

  async function handleSaveClick() {
    await post('/ui/sync/events', {
      enabled: localCustomer.eventTriggering === 'enabled'
    });
    mutate(localCustomer);
    setIsDirty(false);
  }

  const onEnableSwitchChange = () => {
    const originalState = customer.eventTriggering;
    const switchingTo = localCustomer.eventTriggering === 'disabled' ? 'enabled' : 'disabled';

    if (originalState === switchingTo) {
      setLocalCustomer(customer);
      setIsDirty(false);
    } else {
      setLocalCustomer({
        ...localCustomer,
        eventTriggering: switchingTo
      });
      setIsDirty(true);
    }
  };

  const getEnableSwitchState = () => {
    const { eventTriggering } = localCustomer;
    return {
      checked: eventTriggering === 'enabling_in_progress' || eventTriggering === 'enabled',
      disabled: eventTriggering.includes('in_progress')
    };
  };

  const eventSampleDialog = useRef(null);

  const showEventSample = (chart) => {
    if (eventSamplesAreLoading || eventSamplesError) return;

    const sample = eventSamples.find((sample) => sample.eventType === chart.sampleEventType);
    eventSampleDialog.current.open({ headline: chart.title, content: `<pre>${sample.samplePayload}</pre>` });
  };

  return (
    <>
      <e-dialog ref={eventSampleDialog} data-testid="event-sample-dialog"></e-dialog>
      <div className="e-margin-bottom-m">
        <e-notification type="warning">
          <e-notification-content>
            Make sure to enable real time sync only after you checked the status of the initial contact sync in your SAP
            Commerce Cloud account to avoid data synchronization issues.
          </e-notification-content>
        </e-notification>
      </div>
      <p>
        Trigger Emarsys interactions events based on changes in your SAP Commerce Cloud. The following events will be
        synced <strong>cart, customer review, order update</strong>. If contact sync is enabled, you can access{' '}
        <strong>customer registration</strong> and <strong>opt-in status</strong> events as well.
      </p>
      <div className="e-field">
        <div className="e-switch">
          <input
            className="e-switch__input"
            id="switch-withlabel"
            type="checkbox"
            {...getEnableSwitchState()}
            onChange={onEnableSwitchChange}
          />
          <label className="e-switch__toggle" htmlFor="switch-withlabel"></label>
        </div>
        <label className="e-field__label-inline" htmlFor="switch-withlabel">
          Enable real time event sync
        </label>
      </div>
      <button className="e-btn e-btn-primary e-margin-bottom-m" onClick={handleSaveClick} disabled={!isDirty}>
        {customer.eventTriggering.includes('in_progress') && (
          <div className="e-btn__loading e-btn__loading-active">
            <e-spinner data-size="small"></e-spinner>
          </div>
        )}
        Save
      </button>
      <hr className="e-separator e-margin-xl" />
      <h2>Events</h2>
      <table className="e-table e-table-bordered" data-e-version="2">
        <thead>
          <tr>
            <th>Name</th>
            <th className="e-table__col-right">Trigger count in current hour</th>
            <th className="e-table__col e-table__col-actions"></th>
          </tr>
        </thead>
        <tbody>
          {defaultCharts.map((chart) => {
            return (
              <tr key={chart.title}>
                <td>
                  {chart.title}
                  {chart.tooltip && <e-tooltip content={chart.tooltip} type="helper" has-action></e-tooltip>}
                </td>
                <TriggersInLastHour propertyName={chart.propertyName}></TriggersInLastHour>
                <td className="e-table__col e-table__col-actions">
                  <ShowChart propertyName={chart.propertyName} eventName={chart.title}></ShowChart>
                  <e-tooltip valign="baseline" content="Show event sample">
                    <a
                      className="e-svgclickfix"
                      href="#0"
                      onClick={() => showEventSample(chart)}
                      data-testid={`open-sample-${chart.propertyName}`}
                    >
                      <e-icon
                        icon="search"
                        type="table"
                        disabled={eventSamplesAreLoading || eventSamplesError ? 'disabled' : null}
                      ></e-icon>
                    </a>
                  </e-tooltip>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <CustomEvents></CustomEvents>
    </>
  );
}

export default RealTimeEventsPanelContent;
