import useCustomEvents from '../../hooks/useCustomEvents';
import NewCustomEvent from './NewCustomEvent';
import TriggersInLastHour from './TriggersInLastHour';
import ShowChart from './ShowChart';
import { del } from '../../lib/fetcher';

function CustomEvents() {
  const { customEvents, integrationObjectCodes, mutate, isError, isLoading } = useCustomEvents();

  const customEventSavedHandler = ({ customEvents }) => {
    mutate({ integrationObjectCodes, customEvents });
  };

  const handleDeleteClick = ({ id, name }) => {
    window.e.utils.openDestructiveConfirmationDialog({
      headline: `Delete ${name}?`,
      content: 'Once deleted, it cannot be accessed anymore.',
      confirm: {
        label: 'Delete',
        async callback() {
          const optimisticData = {
            integrationObjectCodes,
            customEvents: customEvents.filter((customEvent) => customEvent.id !== id)
          };

          mutate(
            async () => {
              await del(`/ui/custom-events/${id}`, {});
              return optimisticData;
            },
            {
              optimisticData,
              rollbackOnError: true
            }
          );
        }
      },
      cancel: {
        label: 'Cancel',
        callback() {}
      }
    });
  };

  return (
    <>
      <hr className="e-separator e-margin-xl" />
      <NewCustomEvent integrationObjectCodes={integrationObjectCodes} onSave={customEventSavedHandler}></NewCustomEvent>
      <div className="e-grid">
        <div className="e-cell e-cell-auto">
          <h2>Custom Events</h2>
        </div>
        <div className="e-cell">
          <e-dialoghandler
            class="e-btn e-btn-primary"
            disabled={isLoading || isError}
            dialog="#new-custom-event"
            data-testid="new-custom-event-button"
          >
            Add new Custom Event
          </e-dialoghandler>
        </div>
      </div>

      <table className="e-table e-table-bordered" data-e-version="2">
        <thead>
          <tr>
            <th className="e-table__col">Name</th>
            <th className="e-table__col">Integration Object Code</th>
            <th className="e-table__col-right">Trigger count in current hour</th>
            <th className="e-table__col e-table__col-actions"></th>
          </tr>
        </thead>
        <tbody>
          {customEvents && customEvents.length === 0 && (
            <tr>
              <td colSpan="3">
                <div style={{ height: '200px' }}>
                  <div className="e-emptystate">
                    <e-icon className="e-emptystate__icon" icon="table"></e-icon>
                    <div className="e-emptystate__content">
                      <div className="e-emptystate__title">No items found.</div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          )}

          {customEvents &&
            customEvents.map((customEvent) => (
              <tr key={customEvent.id}>
                <td>{customEvent.name}</td>
                <td>{customEvent.integrationObjectCode}</td>
                <TriggersInLastHour propertyName={`custom-event-${customEvent.id}`}></TriggersInLastHour>
                <td className="e-table__col e-table__col-actions">
                  <ShowChart propertyName={`custom-event-${customEvent.id}`} eventName={customEvent.name}></ShowChart>
                  <e-tooltip valign="baseline" content="Delete">
                    <a className="e-svgclickfix" href="#0" onClick={() => handleDeleteClick(customEvent)}>
                      <e-icon icon="trash-o" type="table"></e-icon>
                    </a>
                  </e-tooltip>
                </td>
              </tr>
            ))}

          {isLoading && (
            <tr>
              <td colSpan="3" style={{ textAlign: 'center' }}>
                <e-spinner data-size="table"></e-spinner>
              </td>
            </tr>
          )}

          {isError && (
            <tr>
              <td colSpan="3" style={{ textAlign: 'center' }}>
                <div style={{ height: '200px' }}>
                  <div className="e-emptystate">
                    <e-icon className="e-emptystate__icon" icon="e-error"></e-icon>
                    <div className="e-emptystate__content">
                      <div className="e-emptystate__title">No data to show.</div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}

export default CustomEvents;
